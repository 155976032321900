<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
// import { required } from "vuelidate/lib/validators";
import { paymentMethods } from "@/state/helpers";
import * as cloneDeep from "lodash.clonedeep";
import {
  FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS,
  UPDATE_PAYMENT_METHOD_CREDENTIALS,
  // FETCH_PAYMENT_METHODS_CREDENTIALS,
  ADD_PAYMENT_METHOD_CREDENTIALS,
  FETCH_ALL_PAYMENT_CHANNEL,
  // UPDATE_ENABLE_AND_DEFAULT_STATUS
} from "@/state/actions.type";
import {
  UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE,
  UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE
} from '@/state/mutations.type';
import Multiselect from 'vue-multiselect';
 
const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },

  data() {
    return {
      paymentChannelCreds: null,
      currentChannel: null,
      currentChannelCopy: null,
      addChannelCred: null,
      addChannelCredCopy: null,
      showPayInmodal: false,
      inViewMode:true,
      showAddCredPayInmodal: false,
      isdata: false,
      checked: true,
      checked1: false,
      paymentsMethodsData:[],
      // selected_country:"",
      selected_country_for_add_cred:"",
      mKey: ""
    };
  },

  computed: {
    isValid() {
      if (this.currentChannel) {
        const creds = this.currentChannel.creds;
        for (let cred in creds) {
          if (!creds[cred].value) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    isValidAddCred() {
      if (this.addChannelCred) {
        const creds = this.addChannelCred.creds;
        for (let cred in creds) {
          if (!creds[cred].value) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    paymentChannelCredsList() {
      return this.$store.state.paymentChannels.selectedChannelCreds;
    },
    paymentmethodCredsList() {
      return this.$store.state.paymentChannels.selectedChannelMethods;
    },

    chaiPayKey() {
      return this.$store.getters["auth/iamportKey"];
    },

    // countryList() {
    //   return this.$store.getters["paymentChannels/listOfCountry"];
    // },
    countryListForAddCred() {
      return this.$store.getters["paymentChannels/listOfCountryForAddCred"];
    },
    topBarPayoutData() {
      return this.$store.getters["paymentChannels/topBarPayoutData"];
    },
    // dataForWalletType() {
    //   return this.$store.getters["paymentChannels/dataForWalletType"];
    // },
    // dataForAllType() {
    //   return this.$store.getters["paymentChannels/dataForAllType"];
    // },
    // dataForCardType() {
    //   return this.$store.getters["paymentChannels/dataForCardType"];
    // },
    // dataForAtmCardType() {
    //   return this.$store.getters["paymentChannels/dataForAtmCardType"];
    // },
    // selectedCountry() {
    //   return this.countryList[0];
    // },
    items() {
      var queryParams = new URLSearchParams(window.location.search);
      return [
        {
          text: this.$t("menuitems.integration.list.payconfig"),
          active: true
        },
        {
          text: this.$t("menuitems.integration.list.pay-channels"),
          href: '/settings/payout-channel'
        },
        {
          text: `${queryParams.get("channelName")}`,
          active: true,
        },
      ];
    },

    title() {
      return this.$t("menuitems.integration.list.pay-channels");
      // return this.$t("menuitems.integration.list.pay-channelstwo");
    },

    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },
  },

  created() {
    // if(Object.keys(this.$store.state.paymentChannels.channels).length < 1){
    let payload = {
      country: [],
      paymentMethod: "",
      paymentType: "PAYOUT"
    };
    this.mKey = this.$route.query.mKey;
    if(this.mKey && this.isMasterMerchant) {
      payload.merchant_uuids = this.$route.query.mKey;
    }

    this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, payload);
    // }

    payload = {
      channelName: this.$route.query.channelName,
      payType: "PAYOUT",
    }
    if(this.mKey && this.isMasterMerchant) {
      payload.merchant_uuids = this.$route.query.mKey;
    }
   this.$store.dispatch(`paymentChannels/${FETCH_SELECTED_PAYMENT_CHANNEL_CREDENTIALS}`, payload);
    this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
  },

  watch: {
    paymentChannelCredsList() {
      this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
    },
    paymentmethodCredsList() {
      this.paymentsMethodsData = cloneDeep(this.paymentmethodCredsList);
    },
    // selectedCountry() {
    //   this.selected_country = this.selectedCountry
    // },
    // selected_country() {
      // var queryParams = new URLSearchParams(window.location.search)
      // var qrcode_channelName = queryParams.get("channelName")
      // var selected_country_for_method = ""
      // if(this.paymentChannelCredsList.length > 0 && this.selected_country){
      //   this.paymentChannelCredsList.forEach((channel) => {
      //     if(channel.country == this.selected_country.key){
      //        selected_country_for_method = channel.key
      //     }
      //   });
      //   this.$store.dispatch(`paymentChannels/${FETCH_PAYMENT_METHODS_CREDENTIALS}`, {channel_key: selected_country_for_method})
      // }  
    // },
    selected_country_for_add_cred() {
      this.paymentChannelCredsList.forEach((channel) => {
        if(channel.country == this.selected_country_for_add_cred.key){
            this.addChannelCred = cloneDeep(channel);
            this.addChannelCredCopy = cloneDeep(channel);
        }
      });
    },
  },

  mounted() {
    this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
    this.paymentsMethodsData = cloneDeep(this.paymentmethodCredsList);
    // this.selected_country = this.countryList[0];
  },
    
  destroyed() {
    // document.body.classList.remove("dynamic-body");
    // this.selected_country = {}
    this.$store.commit(`paymentChannels/${UPDATE_PAYMENT_METHODS_DETAILS_IN_STORE}`, []);
    this.$store.commit(`paymentChannels/${UPDATE_SELECTED_PAYMENT_CHANNEL_DETAILS_IN_STORE}`, []);
  },

  validations: {
    paymentChannelCreds: {},
  },

  methods: {
    ...paymentMethods,

    handlePayInSubmit() {
      // stop here if form is invalid
      this.$v.$touch();
      for (var key in this.currentChannel.creds) {
        if(this.currentChannel.creds[key].value.includes("*") && this.currentChannel.creds[key].value[0] == "*") {
          delete this.currentChannelCopy.creds[key];
        } else {
          this.currentChannelCopy.creds[key] = this.currentChannel.creds[key]
        }
      }

      let payload = {
        channelId: this.currentChannel.key,
        payChannel: this.currentChannelCopy,
        payType:"PAYOUT"
      };

      if(this.mKey && this.isMasterMerchant) {
        payload.merchant_uuids = this.mKey;
      }

      this.$store.dispatch(`paymentChannels/${UPDATE_PAYMENT_METHOD_CREDENTIALS}`, payload);
      this.hidePayInModal();
    },
    selectvaflow(p){
      this.addChannelCred.is_va_flow = p;
    },
    handleAddCredPayInSubmit() {
      // stop here if form is invalid
      this.$v.$touch();
      for (var key in this.addChannelCred.creds) {
        if(this.addChannelCred.creds[key].value.includes("*") && this.addChannelCred.creds[key].value[0] == "*") {
          delete this.addChannelCredCopy.creds[key];
        } else {
          this.addChannelCredCopy.creds[key] = this.addChannelCred.creds[key]
        }
      }

      if(this.addChannelCred.key == 'EPAY_PAYOUT'){
        this.addChannelCred.is_va_flow = true;
      }
      else if(['GBPRIMEPAY_PAYOUT', 'APPOTAPAY_PAYOUT', 'BBL_PAYOUT'].includes(this.addChannelCred.key)){
        this.addChannelCred.is_va_flow = false;
      }
      //this.addChannelCredCopy.is_va_flow = this.addChannelCred.is_va_flow;
      let payload = {
        channelId: this.addChannelCred.key,
        payChannel: this.addChannelCredCopy,
        payType:"PAYOUT",
        is_va_flow: this.addChannelCred.is_va_flow,
      }

      if(this.mKey && this.isMasterMerchant) {
        payload.merchant_uuids = this.mKey;
      }
      this.$store.dispatch(`paymentChannels/${ADD_PAYMENT_METHOD_CREDENTIALS}`, payload);

      this.hideAddCredPayInModal();
    },

    // markasEnabledAndDefault(method,cardType) {
    //   if(cardType == "card"){
    //     var data = {
    //         "action_type": "UPDATE_DEFAULT_STATUS",
    //         "method_key": method.key,
    //         "method_sub_type": method.sub_type,
    //         "is_enabled": method.is_default ? false : true
    //     }
    //   }else{
    //     data = {
    //         "action_type": "UPDATE_ENABLE_STATUS",
    //         "method_key": method.key,
    //         "method_sub_type": "",
    //         "is_enabled": method.is_enabled ? false : true
    //     }
    //   }
    //   var selected_country_for_method = ""
    //   this.paymentChannelCredsList.forEach((channel) => {
    //     if(channel.country == this.selected_country.key){
    //        selected_country_for_method = channel.key
    //     }
    //   });
    //   this.$store.dispatch(`paymentChannels/${UPDATE_ENABLE_AND_DEFAULT_STATUS}`, {
    //     statusData: data,
    //     countryForMethod: selected_country_for_method
    //   });
    // },
    
    openPayInModal(channel,viewType) {
      this.showPayInmodal = true;
      this.currentChannel = cloneDeep(channel);
      this.currentChannelCopy = cloneDeep(channel);
      if(viewType == "preview"){
        this.inViewMode = true;
      }else{
        this.inViewMode = false;
      }
    },

    hidePayInModal() {
      this.currentChannel = null;
      this.currentChannelCopy = null;
      this.showPayInmodal = false;
    },
    getChaipayWebhook() {
        var queryParams = new URLSearchParams(window.location.search);
        return `${CHAIPAY_API_URL}/payout/webhook/${queryParams.get("channelName").toLowerCase()}/${this.chaiPayKey}`;
    },

    openAddCredPayInModal() {
      if(this.countryListForAddCred.length > 0){
          this.selected_country_for_add_cred = this.countryListForAddCred[0];
          this.paymentChannelCredsList.forEach((channel) => {
            if(channel.country == this.countryListForAddCred[0].key){
                this.addChannelCred = cloneDeep(channel);
                this.addChannelCredCopy = cloneDeep(channel);
            }
          });
        this.showAddCredPayInmodal = true;
      }else{
          this.NoCountryAvailable();
      }

    },

    hideAddCredPayInModal() {
      this.addChannelCred = null;
      this.addChannelCredCopy = null;
      this.showAddCredPayInmodal = false;
    },

    onCopyWebhookURL() {
      var queryParams = new URLSearchParams(window.location.search);
      this.$notify({
        type: "success",
        text: `PortOne provided webhook for ${queryParams.get("channelName").toLowerCase()} copied to clipboard.`,
        closeOnClick: true,
      });
    },
    NoCountryAvailable() {
      this.$notify({
        type: "info",
        text: this.$t('views.payment_channels.no_more_countries_to_add'),
        closeOnClick: true,
      });
    },
    credentialFieldFormat(key){
      const text = key;
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;

    },
    convertCodeToCountry(val){
      if(val == "VN"){
          return "Vietnam"
      }else if(val== "TH"){
        return "Thailand"
      }else if(val== "SG"){
        return "Singapore"
      }else if(val== "ID"){
        return "Indonesia"
      }else if(val== "MY"){
        return "Malaysia"
      }else if(val== "PH"){
        return "Philippines"
      }else if(val== "TW"){
        return "Taiwan"
      }else if(val== "KE"){
        return "Kenya"
      }else if(val== "ZA"){
        return "South Africa"
      }else if(val== "NG"){
        return "Nigeria"
      }else if(val == "GLOBAL"){
        return "GLOBAL"
      }
    },
    selectedFile(fieldName, fileList) {
      console.log(fileList[0]);
      let file = fileList[0];
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload =  evt => {
        this.addChannelCred.creds[fieldName].value = evt.target.result;
      }
      reader.onerror = evt => {
        console.error(evt);
      }
      
    },
     selectedFileForUpdate(fieldName, fileList) {
      console.log(fileList[0]);
      let file = fileList[0];
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload =  evt => {
        this.currentChannel.creds[fieldName].value = evt.target.result;
      }
      reader.onerror = evt => {
        console.error(evt);
      }
      
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="">
      <div class="pt-0">
          <!-- Payment Channel tab -->
            <div class="row">
              <div data-v-254e0753="" class="col-12 mb-3" style="font-weight: bold; font-size: 18px;" v-if="isMasterMerchant && $route.query.mEmail">{{ $route.query.mEmail }}</div>
              <div class="col-xl-5 col-sm-12">  
                <div
                  class="card border"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                  <div class="card-body" style="padding:0px;">
                      <div class="right-top-section pb-3">
                        <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt />
                        <div class="card-body-custom" style="z-index: 1;">  
                          <div class="media-body media-body-custom">
                            <img
                              :src="`${topBarPayoutData.logo}`"
                              alt
                              class="avatar-custom"
                              style="object-fit: contain; display: block;"
                            />
                            <p class="d-inline-block mb-0">
                              {{ topBarPayoutData.key }} {{ topBarPayoutData.type }}
                            </p>
                          </div>
                      <!-- <b-button v-b-toggle.sidebar-right @click="openAddCredPayInModal()" class="btn btn-custom btn-primary btn-block dark-bg">+ Add Keys</b-button> -->
                          <b-button @click="openAddCredPayInModal()" class="btn btn-custom btn-primary btn-block dark-bg">+ {{ $t('views.payouts.add_keys') }}</b-button>
                        </div>
                      </div>
                      <div class="right-bottom-section mt-3">
                        <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt />
                        <div class="card-body-custom">
                          <div
                            v-for="(data, index) in paymentChannelCredsList"
                            :key="index">
                            <div class="cred-section mt-3" v-if="data.is_enabled">
                              <!-- <div class="creds-container">
                                <div class="value-class country-class">{{convertCodeToCountry(data.country)}}</div>
                                <button class="btn btn-primary float-right btn-sm" @click="openPayInModal(data,'preview')">Manage Keys</button>
                              </div> -->
                              <div class="creds-container">
                                <div class="creds-container-top-section mb-2">
                                  <div class="cursor-pointer value-class d-inline-block country-class">{{convertCodeToCountry(data.country)}}</div>
                                  <button class="btn btn-primary float-right btn-sm" @click="openPayInModal(data,'preview')">{{  $t("views.payment_channels.manage_keys") }}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div
                  class="card border"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                  <div class="card-body card-body-custom">
                      <form id="modal" action="#">
                        <div class="form-group">
                          <label style="" for="webhookUrl">{{ $t('views.payouts.webhook_endpoint') }}</label>
                          <i class="ri-file-copy-line ml-2 align-middle cursor-pointer copy-icon"
                          v-clipboard:copy="getChaipayWebhook()"
                          v-clipboard:success="onCopyWebhookURL"
                          v-b-tooltip.hover
                          :title="$t('common.copy')"></i>
                          <input
                            id="webhookUrl"
                            type="text"
                            class="form-control"
                            name="webhookUrl"
                            v-clipboard:copy="getChaipayWebhook()"
                            :value="getChaipayWebhook()"
                            readonly
                          />
                          <b-tooltip target="webhookUrl">
                            <p>
                              {{ $t('views.payouts.config_tooltip') }}
                            </p>
                          </b-tooltip>
                        </div>
                      </form>
                  </div>
                </div>
              </div>  
              <!-- <div
                class="col-xl-7 col-sm-12">
                <div
                  class="card border"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                  <div class="card-body card-body-custom">
                    <div class="county-wallet-top-bar mb-3 mt-1">
                      <img src="@/assets/images/country.png" alt />
                      <p class="d-inline ml-2">Country</p>
                    </div>
                    <div class="col-md-8 row">
                      <multiselect
                        v-model="selected_country"
                        :options="countryList"
                        track-by="value" 
                        label="value"
                        placeholder="Select Country"
                        size="sm"
                        :searchable="false"
                        :multiple="false"
                        :taggable="true"
                        :allowEmpty="false"
                        :show-labels="false"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                  <div
                  class="card border" v-if="dataForWalletType.length > 0"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                    <div class="card-body card-body-custom">
                      <div class="county-wallet-top-bar mb-3 mt-1">
                        <img src="@/assets/images/wallet.png" alt />
                        <p class="d-inline ml-2">Wallets</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForWalletType"
                        :key="index"
                        class="cred-section mt-4">
                        <div class="row">
                          <div class="col-md-3">{{ data.name }}</div>
                          <div class="col-md-8">
                          <toggle-button @change="markasEnabledAndDefault(data)"
                          :value="data.is_enabled"
                          :width=80
                          :font-size=11
                          :height=25
                          :margin=3
                          :color="{checked: '#252B3A', unchecked: '#BFCBD9', disabled: '#CCCCCC'}"
                          :labels="{checked: 'Enabled', unchecked: 'Disabled'}"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                  class="card border" v-if="dataForCardType.length > 0"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                    <div class="card-body card-body-custom">
                      <div class="county-wallet-top-bar mb-3 mt-1">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline ml-2">Credit Cards</p>
                      </div>
                      <div
                        v-for="(data) in dataForCardType"
                        :key="data.key"
                        class="cred-section mt-4">
                        <div class="row">
                          <div class="col-md-3"><span class="d-inline-block align-middle">{{ data.name }}</span></div>
                          <div class="col-md-8">
                          <toggle-button @change="markasEnabledAndDefault(data)"
                          :value="data.is_enabled"
                          :width=80
                          :font-size=11
                          :height=25
                          :margin=3
                          :color="{checked: '#252B3A', unchecked: '#BFCBD9', disabled: '#CCCCCC'}"
                          :labels="{checked: 'Enabled', unchecked: 'Disabled'}"/>
                            <div class="default-class d-inline ml-5"
                            v-if="data.is_default">
                              <i class="bx ri-checkbox-circle-line check-class"></i> Default
                            </div>
                            <button
                              href="javascript:void(0);"
                              class="btn btn-primary ml-5 pt-1 pb-1"
                              v-if="!data.is_default"
                              style="font-size: 12px; border: 1px solid #DAD9D9; color: #D9D9D9; background: #fff;"
                              @click="markasEnabledAndDefault(data,'card')"
                            >
                              Make Default
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                  class="card border" v-if="dataForAtmCardType.length > 0"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                    <div class="card-body card-body-custom">
                      <div class="county-wallet-top-bar mb-3 mt-1">
                        <img src="@/assets/images/card.png" alt />
                        <p class="d-inline ml-2">ATM Cards</p>
                      </div>
                      <div
                        v-for="(data) in dataForAtmCardType"
                        :key="data.key"
                        class="cred-section mt-4">
                        <div class="row">
                          <div class="col-md-3"><span class="d-inline-block align-middle">{{ data.name }}</span></div>
                          <div class="col-md-8">
                          <toggle-button @change="markasEnabledAndDefault(data)"
                          :value="data.is_enabled"
                          :width=80
                          :font-size=11
                          :height=25
                          :margin=3
                          :color="{checked: '#252B3A', unchecked: '#BFCBD9', disabled: '#CCCCCC'}"
                          :labels="{checked: 'Enabled', unchecked: 'Disabled'}"/>
                            <div class="default-class d-inline ml-5"
                            v-if="data.is_default">
                              <i class="bx ri-checkbox-circle-line check-class"></i> Default
                            </div>
                            <button
                              href="javascript:void(0);"
                              class="btn btn-primary ml-5 pt-1 pb-1"
                              v-if="!data.is_default"
                              style="font-size: 12px; border: 1px solid #DAD9D9; color: #D9D9D9; background: #fff;"
                              @click="markasEnabledAndDefault(data,'card')"
                            >
                              Make Default
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                  class="card border" v-if="dataForAllType.length > 0"
                  style="border-radius: 10px;box-shadow: 0px 3px 10px #0000001A;">
                    <div class="card-body card-body-custom">
                      <div class="county-wallet-top-bar mb-3 mt-1">
                        <img src="@/assets/images/wallet.png" alt />
                        <p class="d-inline ml-2">All</p>
                      </div>
                      <div
                        v-for="(data, index) in dataForAllType"
                        :key="index"
                        class="cred-section mt-4">
                        <div class="row">
                          <div class="col-md-3">{{ data.name }}</div>
                          <div class="col-md-8">
                          <toggle-button @change="markasEnabledAndDefault(data)"
                          :value="data.is_enabled"
                          :width=80
                          :font-size=11
                          :height=25
                          :margin=3
                          :color="{checked: '#252B3A', unchecked: '#BFCBD9', disabled: '#CCCCCC'}"
                          :labels="{checked: 'Enabled', unchecked: 'Disabled'}"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div> -->
            </div>
      </div>

      <!-- Payment Channel Modal -->
      <div>
        <!-- Right Sidebar -->
        <div class="right-bar"
        :class="{
            'active': showPayInmodal
          }">
          <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt />
          <div v-if="currentChannel" data-simplebar class="h-100">
            <div class="rightbar-title pb-3">
              <div class="right-top-section">
                <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt />
                <div class="card-body-custom">
                  <i class="ri-pencil-fill cursor-pointer edit-button" v-if="inViewMode" @click="openPayInModal(currentChannel,'edit')"></i>
                  <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hidePayInModal"></i>
                  <div class="media-body media-body-custom">
                    <img
                      :src="`${topBarPayoutData.logo}`"
                      alt
                      class="avatar-custom"
                      style="object-fit: contain; display: block;"
                    />
                    <p class="d-inline-block mb-0">
                      {{ topBarPayoutData.key }} {{ topBarPayoutData.type }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3">
              <form id="modal" action="#" @submit.prevent="handlePayInSubmit">
                <div class="form-group">
                  <label style="color: #B3AFAF;">{{ $t('views.payouts.country') }}</label>
                  <div class="form-control">{{convertCodeToCountry(currentChannel.country)}}</div>
                </div>
                <div
                  class="form-group"
                  v-for="(value, key) in currentChannel.creds"
                  :key="key"
                >
                  <div v-if="currentChannel.creds[key].type == 'file'">
                    <label :for="key" style="color: #B3AFAF;">{{ key }}</label>
                    <div v-if="!inViewMode" class="position-relative mb-3">
                      <div class="bulk-upload-button btn-sm">{{ $t('button.select_file') }}</div>
                      <input type="file" :id="key" multiple :name="key" @change="selectedFileForUpdate($event.target.name, $event.target.files)" class="file_Upload input-file">
                    </div>
                    <textarea v-model="currentChannel.creds[key].value" readOnly disabled class="w-100 form-control" placeholder="Preview for Keys"></textarea>
                  </div>
                  <div v-if="currentChannel.creds[key].type == 'text'">
                    <label :for="key" style="color: #B3AFAF;">{{ key }}</label>
                    <input   
                      :id="key"
                      v-model="currentChannel.creds[key].value"
                      type="text"
                      class="form-control"
                      :placeholder="`Enter ${key}`"
                      :name="key"
                      :readonly="inViewMode"
                    />
                  </div>
                </div>
                <div v-if="!inViewMode" class="text-left">
                  <button
                    type="submit"
                    class="btn btn-success dark-bg ml-1 col-4"
                    :disabled="!isValid"
                  >
                    {{ $t('button.save') }}
                  </button>
                  <b-button class="ml-2 col-4" variant="light" @click="hidePayInModal"
                    >{{ $t('button.cancel') }}</b-button>
                </div>
              </form>
            </div>
          </div>
          <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hidePayInModal" class="rightbar-overlay"
        :class="{
            'active': showPayInmodal
        }"></div>
      </div>
      <!-- Payment Channel Modal For Add Cred -->
      <div>
        <!-- Right Sidebar -->
        <div class="right-bar"
        :class="{
            'active': showAddCredPayInmodal
          }">
          <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt />
          <div v-if="addChannelCred" data-simplebar class="h-100">
            <div class="rightbar-title pb-3">
              <div class="right-top-section">
                <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt />
                <div class="card-body-custom">  
                   <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hideAddCredPayInModal"></i>
                  <div class="media-body media-body-custom">
                    <img
                      :src="`${topBarPayoutData.logo}`"
                      alt
                      class="avatar-custom"
                      style="object-fit: contain; display: block;"
                    />
                    <p class="d-inline-block mb-0">
                      {{ topBarPayoutData.key }} {{ topBarPayoutData.type }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3">
              <form id="modal" action="#" @submit.prevent="handleAddCredPayInSubmit">
                <multiselect
                  v-model="selected_country_for_add_cred"
                  :options="countryListForAddCred"
                  placeholder="Select Country"
                  track-by="value" 
                  label="value"
                  size="sm"
                  class="mb-3 custom-multiselect cursor-pointer"
                  :searchable="false"
                  :multiple="false"
                  :taggable="true"
                  :allowEmpty="false"
                  :show-labels="false"
                ></multiselect>
                <div
                  class="form-group"
                  v-for="(value, key) in addChannelCred.creds"
                  :key="key"
                >
                  <div v-if="addChannelCred.creds[key].type == 'file'">
                    <label :for="key" style="color: #B3AFAF;">{{ key }}</label>
                    <div class="position-relative">
                      <div class="bulk-upload-button btn-sm">{{ $t('button.select_file') }}</div>
                      <input type="file" :id="key" multiple :name="key" @change="selectedFile($event.target.name, $event.target.files)" class="file_Upload input-file">
                    </div>
                    <textarea v-model="addChannelCred.creds[key].value" readOnly disabled class="mt-3 w-100 form-control" placeholder="Preview for Keys"></textarea>
                  </div>
                  <div v-if="addChannelCred.creds[key].type == 'text'">
                    <input   
                      :id="key"
                      v-model="addChannelCred.creds[key].value"
                      type="text"
                      class="form-control"
                      :placeholder="`Enter ${credentialFieldFormat(key)}`"
                      :name="key"
                    />
                  </div>
                </div>
                <div class="form-group" v-if="!['GBPRIMEPAY_PAYOUT', 'APPOTAPAY_PAYOUT', 'BBL_PAYOUT', 'PAYNAMICS_PAYOUT'].includes(addChannelCred.key)">
                  <label style="color: #B3AFAF;">Use Virtual Account Flow</label>
                  <div v-if="addChannelCred.key == 'EPAY_PAYOUT'">
                    <b-form-checkbox
                      id="is_va_flow"   
                      v-model="checked"
                      name="is_va_flow"
                      switch
                      disabled 
                    >
                    </b-form-checkbox>
                  </div>
                  <div v-else>
                    <b-form-checkbox
                      id="is_va_flow"    
                      v-model="addChannelCred.is_va_flow"
                      @change=selectvaflow(addChannelCred.is_va_flow)
                      name="is_va_flow"
                      switch
                    >
                    </b-form-checkbox>
                  </div>

                    
                  </div>
                <div class="text-left">
                  <button
                    type="submit"
                    class="btn btn-success dark-bg ml-1 col-4"
                    :disabled="!isValidAddCred"
                  >
                    {{ $t('button.save') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hideAddCredPayInModal" class="rightbar-overlay"
        :class="{
            'active': showAddCredPayInmodal
        }"></div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
input:focus:required:invalid {
  color: green;
}

::v-deep .nav-tabs-custom {
  margin-bottom: 24px;
}
.media-body-custom{
    display: inline-block;
    vertical-align: middle;
}
.card-body-custom{
  padding:15px 20px;
  position: relative;
}
.btn-custom{
    width: 140px;
    float: right;
    margin-top: 30px;
    background-color: #5664d2;
    border-color: #5664d2;
}

.creds-container{
  padding: 10px;
  position: relative;
  border: 0.5px solid #00246414;
  border-radius: 4px;
}
.edit-button{
  position: absolute;
  right: 45px;
  top: 15px;
  font-size: 15px;
  cursor: pointer;
}
.view-button{
  position: absolute;
  right: 40px;
  top: 8px;
  font-size: 18px;
  cursor: pointer;
}
.vue-js-switch{
  margin-bottom: 0px;
}
.check-class{
    padding-right: 5px;
    position: relative;
    top: 3px;
    color:#1cbb8c;
    font-weight: bold;
}
.county-wallet-top-bar img{
    height:18px;
    width:18px;
    display:inline-block;
    vertical-align: middle;
}
.county-wallet-top-bar p{
    display:inline-block;
    vertical-align: middle;
}
.dark-bg{
  background-color: #252B3A;
  border-color: #252B3A;
}
.right-top-section{
  /* border-bottom: 1px solid #D9D9D9; */
  background-color: #F7F7F7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
.manage-credentials{
    background-color: #eaeaea;
    border-radius: 4px;
    font-weight: 600;
}
.right-bar{
  width: 25%;
  right: -25%;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color:#252B3B;
}
.cursor-pointer{
  cursor: pointer;
}
.right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 0.5px solid #00246414;;
}
::v-deep .custom-multiselect .multiselect__select:before{
  z-index: 1;
}
.file_Upload{
    opacity: 0;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0px;
    width: 140px;
    z-index: 9999;
    cursor: pointer;
}
.bulk-upload-button{
    width: 120px!important;
    text-align: center;
    color: #FFFFFF;
    background-color: #252B3A;
    cursor: pointer;
    border-radius: 5px;
    display: block;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}
.avatar-custom{
    height: 70px;
    width: 95px;
}
.copy-icon{
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 18px;
}
#webhookUrl{
    padding-right: 35px;
}
@media (max-width: 575px) {
  ::v-deep .page-title-box{
    display: block !important;
  }
  ::v-deep .page-title-box h4{
    margin-bottom: 5px!important;
  }
}
@media (max-width: 1300px) {
  .right-bar{
    width: 360px;
    right: -360px;
  }
}
@media (max-width: 400px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
</style>